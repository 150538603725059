export const SCREENS = {
  START: 4,
  PLAYERS: 0,
  FIGHT: 1,
  VERSUS: 2,
  WIN: 3,
  DLC: 5,
  LADDER: 6,
  TO_BE_CONTINUED: 7,
}

